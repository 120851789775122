import React, {useEffect} from 'react'
import { Link } from "react-router-dom";

import { FaAngleDoubleRight, FaAngleUp, FaFacebook, FaInstagram, FaLinkedin, FaPaperPlane, FaPinterestP, FaTwitter } from 'react-icons/fa';
import thumb1 from "../Assets/gallery-thumb-1.webp"
import thumb2 from "../Assets/gallery-thumb-2.webp"
import thumb3 from "../Assets/gallery-thumb-3.webp"
import thumb4 from "../Assets/gallery-thumb-4.webp"
import thumb5 from "../Assets/gallery-thumb-5.webp"
import thumb6 from "../Assets/gallery-thumb-6.webp"

const Footer = () => {
  useEffect(() => {
    const scrollToTopButton = document.querySelector('.scroll-to-top');
    scrollToTopButton.addEventListener('click', () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    });
    return () => {
        scrollToTopButton.removeEventListener('click', () => {});
    };
}, []);


  return (
    <>
     <div className="widgets-section">
     <div class="bg bg-image" ></div>
      <div className="container">
        <div className="row paading-main">

          <div className="footer-column col-lg-3 col-sm-6">
            <div className="footer-widget about-widget">
            <h4 className="widget-title">NexGen Global Logistics</h4>
             
              <div className="text-footer text-justify">With a global reach, NexGen specializes in air freight, supply chain management serving a diverse range of industries worldwide.</div>
              <Link to="/about" className="theme-btn btn-style-one"><span className="btn-title">About Us</span></Link>
            </div>
            
          </div>

          <div className="footer-column col-lg-3 col-sm-6">
            <div className="footer-widget links-widget">
              <h4 className="widget-title">Service</h4>
              <div className="widget-content">
                <ul className="user-links">
                  <li><FaAngleDoubleRight /> <Link to="/heavy">Heavy haul</Link></li>
                  <li><FaAngleDoubleRight /> <Link to="/Lft">Air Freight</Link></li>
                  <li><FaAngleDoubleRight /> <Link to="/ocean">Ocean Freight</Link></li>
                  <li><FaAngleDoubleRight /> <Link to="/ware">Drayage And Warehousing</Link></li>
                  <li><FaAngleDoubleRight /> <Link to="/supply">Supply Chain</Link></li>
                  <li><FaAngleDoubleRight /> <Link to="/packaging">Packaging</Link></li>
                </ul>
              </div>
            </div>
          </div>

          <div className="footer-column col-lg-3 col-sm-6">
            <div className="footer-widget gallery-widget">
              <h4 className="widget-title">Projects</h4>
              <div className="widget-content">
                <div className="insta-gallery">
                  <figure className="image"><Link to="/service"><img src={thumb1} alt='' /></Link></figure>
                  <figure className="image"><Link to="/service"><img src={thumb2} alt='' /></Link></figure>
                  <figure className="image"><Link to="/service"><img src={thumb3} alt='' /></Link></figure>
                  <figure className="image"><Link to="/service"><img src={thumb4} alt='' /></Link></figure>
                  <figure className="image"><Link to="/service"><img src={thumb5} alt='' /></Link></figure>
                  <figure className="image"><Link to="/service"><img src={thumb6} alt='' /></Link></figure>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-column col-lg-3 col-sm-6">
            <div className="footer-widget newsletter-widget">
              <h4 className="widget-title">Newsletter</h4>
              <div className="text-footer">Subscribe our newsletter to get our latest update &amp; news</div>

              <div className="newsletter-form">
                <div className="form-group">
                  <input type="email" name="Email" placeholder="Email....." required="" />
                  <button type="submit" className="form-btn"><FaPaperPlane /></button>
                </div>
              </div>

              <div className="text-font">
               <Link to="/privacy-policy"> <p ><FaAngleDoubleRight />Privacy & Policy</p></Link>
               <Link to="/disclaimer"> <p ><FaAngleDoubleRight />Disclaimer</p></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
            <div className="container">
                <div className="inner-container">
                    <div className="scroll-to-top scroll-to-target" data-target="html" style={{ display: 'block' }}>
                       <FaAngleUp />
                    </div>
                    <div className="copyright-text">© 2024 NexGen Global Logistics. All Rights Reserved. Developed By NxEdge Solutions</div>
                    <ul className="social-icon-one light">
                        <li><Link to="https://www.facebook.com/"><FaFacebook /></Link></li>
                        <li><Link to="https://www.twitter.com/"><FaTwitter /></Link></li>
                        <li><Link to="https://www.instagram.com/"><FaInstagram /></Link></li>
                        <li><Link to="https://www.linkedin.com/company/nexgen-global-logistics-llc/"><FaLinkedin /></Link></li>
                    </ul>
                </div>
            </div>
        </div>
    </div> 
   
    </>
  )
}

export default Footer
