import React from 'react';
import { FaAngleRight, FaEnvelope, FaPencilAlt, FaPhoneAlt, FaUserAlt } from 'react-icons/fa'

import { Link } from 'react-router-dom';
import image1 from "../Assets/roadways.webp"
import image2 from "../Assets/24177.webp"
import image3 from "../Assets/truck.webp"

function HeavyHaul() {
  return (
<>
    <section className="page-title page-titles">
    <div className="container">
      <div className="title-outer text-center">
        <h1 className="title">Services</h1>
        <ul className="page-breadcrumb">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <FaAngleRight />
          </li>
          <li>Heavy Haul</li>
        </ul>
      </div>
    </div>
  </section> 

    <section className="single-service">
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <div className="service-page1-left">
              <div className="page1-img-text">
                <img src={image1} alt="" />
                <h2>Heavy Haul Transportation: Your Trusted Partner in Moving the Unmovable </h2>
                <p>
                At the forefront of heavy haul transportation,<strong> NexGen Global Logistics </strong> stands as undisputed experts, committed to delivering your heaviest and most oversized cargoes with unmatched precision and care. Our extensive fleet includes specialized equipment such as lowboys, RGNs (Removable Gooseneck Trailers), perimeter trailers, and dual-lane trailers, each designed to cater to the most demanding haulage needs. Lowboys provide a low deck height ideal for tall and heavy machinery, while RGNs allow easy front loading and unloading, perfect for bulky construction equipment. Perimeter trailers distribute weight evenly, ensuring safe transport of extremely heavy and oversized loads, and dual-lane trailers offer maximum stability for the heaviest cargoes. We tailor our transportation solutions to meet your unique challenges, ensuring safe, efficient, and timely delivery from planning through execution, including route surveys, permits, and escorts. With our team's extensive experience, unwavering commitment to safety, and efficient logistics, NexGen Global Logistics ensures your heaviest cargoes are in the most capable hands.
                </p>
               
                <h3>The Most Reliable And Secure Heavy Haul Transportation </h3>
                <img src={image2} alt="" />
                <p>We tailor our transportation solutions to meet your unique challenges, ensuring safe, efficient, and timely delivery from planning through execution, including route surveys, permits, and escorts. Our team handles every aspect of the logistics process, providing you with peace of mind and allowing you to focus on your core business. We prioritize safety above all, adhering to the highest industry standards and regulations to protect your cargo and our crew. </p>
                <h5>Are you searching for the best Heavy Haul Transportation  for reliable
                  shifting? </h5>
                <p>With <strong > NexGen Global Logistics </strong>,  you benefit from our extensive experience, state-of-the-art equipment, and a dedicated team of professionals who are committed to excellence. Our proven track record of successful deliveries speaks to our reliability and expertise. When you choose NexGen Global Logistics, you're choosing the most reliable and secure heavy haul transportation partner, ensuring that your most challenging cargoes are in the best possible hands. Trust us to move your world.</p>

              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="page1-left-text">
                    <p>Selecting the most cost-effective and reliable modes of transportation is crucial for timely delivery. Whether by road, rail, sea, or air, each mode offers its advantages and challenges that must be carefully evaluated based on factors such as distance, urgency, and cost. </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="page1-right-pic">
                    <img src={image3} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
          <div className="service-page1-right">
              <div className="service-menu">
                <h6>Service Menu</h6>
                <ul>
                <Link to="/heavy"> <li>Heavy haul <FaAngleRight /></li></Link> 
                <Link to="/Lft">  <li>Air Freight <FaAngleRight /></li></Link> 
                <Link to="/ocean">  <li>Ocean Freight  <FaAngleRight /></li></Link> 
                <Link to="/ware">  <li>Drayage And Warehousing <FaAngleRight /></li></Link> 
                <Link to="/supply">  <li>Supply Chain <FaAngleRight /></li></Link> 
                <Link to="/packaging">   <li>Packaging <FaAngleRight /></li></Link> 
                 
                </ul>
              </div>
              <div className="service-menu">
                <h6>Fill The Form</h6>
                <div className="form-fill">  <span>
                  <FaUserAlt /></span>
                  <input type="text" placeholder="Full Name" />
                  <span><FaEnvelope /></span>
                  <input type="email" placeholder="Your Email" />
                  <span><FaPhoneAlt /></span>
                  <input type="number" placeholder="Phone Number" />
                  <span><FaPencilAlt /></span>
                  <textarea name="" id="" cols="30" rows="7"></textarea>
                  <button>Send Now</button>
                </div>
              </div>
            </div> 
          </div>
        </div>
      </div>
    </section>
    </>
  );
}

export default HeavyHaul;


