import React from 'react';
import { Link } from 'react-router-dom';
import image1 from "../Assets/warehouse1.webp"
import image2 from "../Assets/96930485.webp"
import image3 from "../Assets/warehouse2.webp"
import { FaAngleRight, FaEnvelope, FaPencilAlt, FaPhoneAlt, FaUserAlt } from 'react-icons/fa'


function Warehousing() {
  return (
<>
    <section className="page-title page-titles">
    <div className="container">
      <div className="title-outer text-center">
        <h1 className="title">Services</h1>
        <ul className="page-breadcrumb">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <FaAngleRight />
          </li>
          <li>Warehousing</li>
        </ul>
      </div>
    </div>
  </section> 

    <section className="single-service">
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <div className="service-page1-left">
              <div className="page1-img-text">
                <img src={image1} alt="" />
                <h2>Warehousing and Inventory Management Excellence with NexGen Global Logistics </h2>
                <p>
                NexGen offers premier drayage and warehousing solutions as part of our comprehensive global logistics services. With a commitment to efficiency and reliability, we handle the seamless transportation and storage of goods worldwide. Trust NexGen for tailored logistics solutions that meet your specific needs, ensuring smooth operations across the supply chain.
                </p>
                <p>NexGen offers comprehensive drayage and warehousing solutions for global logistics needs. Our expert team ensures seamless transportation and storage of goods, optimizing supply chain efficiency. With state-of-the-art facilities and strategic partnerships worldwide, we provide reliable services tailored to your unique requirements. Trust NexGen for unparalleled logistics excellence.</p>
                
                <h3>Efficient Drayage and Warehousing Solutions for Seamless Logistics at NexGen </h3>
                <img src={image2} alt="" />
                <p>Welcome to NexGen, your premier destination for global logistics solutions. Our comprehensive services include efficient drayage and warehousing solutions tailored to meet your specific needs. With a worldwide network and a commitment to excellence, we ensure seamless transportation and storage of your goods. Trust NexGen for reliable, cost-effective logistics services that drive your business forward. Experience the difference with NexGen today."</p>
                <h5>Next-Level Drayage and Warehousing Solutions for Seamless Logistics </h5>
                <p>At NexGen, we redefine excellence in drayage and warehousing services for global logistics. With a commitment to efficiency and reliability, we offer unparalleled solutions tailored to your unique needs. Our extensive network and cutting-edge technology ensure seamless transportation and storage of goods worldwide. Trust NexGen for expert handling, secure storage, and optimized logistics solutions." </p>

              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="page1-left-text">
                    <h6>Efficient Drayage and Warehousing Solutions for Your Logistics Needs at NexGen </h6>
                    <p>NexGen offers expert drayage and warehousing solutions for global logistics needs. Our comprehensive services ensure seamless transportation and storage of goods worldwide. Trust NexGen for efficient, reliable, and cost-effective logistics solutions tailored to your business requirements. </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="page1-right-pic">
                    <img src={image3} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
          <div className="service-page1-right">
              <div className="service-menu">
                <h6>Service Menu</h6>
                <ul>
                <Link to="/heavy"> <li>Heavy haul <FaAngleRight /></li></Link> 
                <Link to="/Lft">  <li>Air Freight <FaAngleRight /></li></Link> 
                <Link to="/ocean">  <li>Ocean Freight  <FaAngleRight /></li></Link> 
                <Link to="/ware">  <li>Drayage And Warehousing <FaAngleRight /></li></Link> 
                <Link to="/supply">  <li>Supply Chain <FaAngleRight /></li></Link> 
                <Link to="/packaging">   <li>Packaging <FaAngleRight /></li></Link> 
                 
                </ul>
              </div>
              <div className="service-menu">
                <h6>Fill The Form</h6>
                <div className="form-fill">  <span>
                  <FaUserAlt /></span>
                  <input type="text" placeholder="Full Name" />
                  <span><FaEnvelope /></span>
                  <input type="email" placeholder="Your Email" />
                  <span><FaPhoneAlt /></span>
                  <input type="number" placeholder="Phone Number" />
                  <span><FaPencilAlt /></span>
                  <textarea name="" id="" cols="30" rows="7"></textarea>
                  <button>Send Now</button>
                </div>
              </div>
            </div> 
          </div>
        </div>
      </div>
    </section>
    </>
  );
}

export default Warehousing;


