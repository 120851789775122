import React from 'react';
import { Link } from 'react-router-dom';
import image1 from "../Assets/packaging1.webp"
import image2 from "../Assets/packaging2.webp"
import image3 from "../Assets/packaging3.webp"
import { FaAngleRight, FaEnvelope, FaPencilAlt, FaPhoneAlt, FaUserAlt } from 'react-icons/fa'


function Packaging() {
  return (
    <>
      <section className="page-title page-titles">
        <div className="container">
          <div className="title-outer text-center">
            <h1 className="title">Services</h1>
            <ul className="page-breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <FaAngleRight />
              </li>
              <li>Packaging</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="single-service">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="service-page1-left">
                <div className="page1-img-text">
                  <img src={image1} alt="" />
                  <h2>Packaging Solutions Tailored for Every Shipment </h2>
                  <p>
                    At NexGen, we redefine excellence in global logistics. With our innovative approach and cutting-edge technology, we offer unparalleled packaging solutions tailored to meet your needs. From efficient supply chain management to seamless transportation, our experienced team ensures your cargo reaches its destination safely and on time. Experience the future of logistics with NexGen.
                  </p>
                  <p>Welcome to NexGen, your premier global logistics partner. With our unparalleled expertise and cutting-edge solutions, we redefine excellence in transportation and supply chain management. From seamless freight forwarding to meticulous cargo handling, our team ensures your goods reach their destination swiftly and securely. Experience the next generation of logistics with NexGen. Trust us to deliver beyond boundaries. </p>
                  <p>Welcome to NexGen, your premier destination for cutting-edge logistics solutions. With our strong global network and unwavering commitment to excellence, we deliver unparalleled packaging services tailored to meet your every need. From intricate supply chain management to seamless freight forwarding, trust NexGen to navigate the complexities of the modern global market with precision and expertise. Experience the power of innovation and reliability with NexGen today. </p>
                  <h3> Safe Delivery with Expert Packaging Strategies </h3>
                  <img src={image2} alt="" />
                  <p>At NexGen, we redefine excellence in logistics services. With a commitment to innovation and efficiency, we deliver seamless and reliable solutions for your global shipping needs.

                    Our comprehensive transportation network ensures that your cargo reaches its destination safely and on time. From air and ocean freight to ground transportation, trust NexGen for end-to-end logistics support.

                    Experience the future of logistics with NexGen. Let's move your business forward, together. </p>
                  <h5>Innovative Packaging Techniques for Secure Transportation</h5>
                  <p>Welcome to NexGen, your premier global logistics partner. At NexGen, we redefine excellence in logistics services with our unparalleled commitment to efficiency, reliability, and innovation. With a global network spanning continents, we deliver seamless solutions tailored to meet your every transportation need. Whether it's heavy haulage, express delivery, or specialized freight, trust NexGen to deliver your cargo with precision and speed. Experience the future of logistics with NexGen – where every shipment is handled with the utmost care and expertise. </p>

                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="page1-left-text">
                      <h6>Customized Packaging Services for Your Unique Needs</h6>
                      <p>NexGen: Delivering Excellence Globally in Logistics. Expertise, Efficiency, and Innovation Redefined. Your Trusted Partner for Seamless Worldwide Transportation Solutions. </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="page1-right-pic">
                      <img src={image3} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
            <div className="service-page1-right">
              <div className="service-menu">
                <h6>Service Menu</h6>
                <ul>
                <Link to="/heavy"> <li>Heavy haul <FaAngleRight /></li></Link> 
                <Link to="/Lft">  <li>Air Freight <FaAngleRight /></li></Link> 
                <Link to="/ocean">  <li>Ocean Freight  <FaAngleRight /></li></Link> 
                <Link to="/ware">  <li>Drayage And Warehousing <FaAngleRight /></li></Link> 
                <Link to="/supply">  <li>Supply Chain <FaAngleRight /></li></Link> 
                <Link to="/packaging">   <li>Packaging <FaAngleRight /></li></Link> 
                 
                </ul>
              </div>
              <div className="service-menu">
                <h6>Fill The Form</h6>
                <div className="form-fill">  <span>
                  <FaUserAlt /></span>
                  <input type="text" placeholder="Full Name" />
                  <span><FaEnvelope /></span>
                  <input type="email" placeholder="Your Email" />
                  <span><FaPhoneAlt /></span>
                  <input type="number" placeholder="Phone Number" />
                  <span><FaPencilAlt /></span>
                  <textarea name="" id="" cols="30" rows="7"></textarea>
                  <button>Send Now</button>
                </div>
              </div>
            </div> 
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Packaging;


