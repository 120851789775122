import React from 'react';
import { Link } from 'react-router-dom';
import image1 from "../Assets/chain1.webp"
import image2 from "../Assets/chain2.webp"
import image3 from "../Assets/chain3.webp"
import { FaAngleRight, FaEnvelope, FaPencilAlt, FaPhoneAlt, FaUserAlt } from 'react-icons/fa'


function SupplyChain() {
  return (
    <>
      <section className="page-title page-titles">
        <div className="container">
          <div className="title-outer text-center">
            <h1 className="title">Services</h1>
            <ul className="page-breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <FaAngleRight />
              </li>
              <li>Supply Chain</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="single-service">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="service-page1-left">
                <div className="page1-img-text">
                  <img src={image1} alt="" />
                  <h2>NexGen:  Supply Chain Logistics </h2>
                  <p>
                    NexGen: Your premier global logistics partner, delivering seamless supply chain solutions worldwide. From sourcing to distribution, we optimize every step for efficiency and reliability. Trust NexGen for unparalleled logistics expertise, ensuring your goods reach their destination swiftly and securely.
                  </p>
                  <p>NexGen delivers unparalleled supply chain solutions for global logistics, empowering businesses with seamless connectivity and optimized operations. Our robust network and cutting-edge technologies ensure efficient movement of goods worldwide. With a relentless commitment to excellence, NexGen pioneers the future of logistics, providing end-to-end services tailored to your needs. Experience the power of innovation and reliability with NexGen." </p>
                  <p>Certainly! Here's a brief description for your website: {""}

                    <strong>NexGen Global Logistics</strong>. We're your premier partner in supply chain solutions, offering unparalleled expertise in navigating the complexities of international logistics. From seamless transportation management to optimized warehousing and distribution, NexGen delivers innovative solutions tailored to your needs. Experience the future of logistics with NexGen. </p>
                  <h3>Efficiency Through NexGen: Supply Chain Solutions </h3>
                  <img src={image2} alt="" />
                  <p>At NexGen, we redefine excellence in global logistics, offering a comprehensive suite of supply chain solutions tailored to meet your every need. With a relentless commitment to efficiency and reliability, we seamlessly integrate cutting-edge technology with unmatched expertise to optimize your supply chain from end to end.

                    Our robust network spans the globe, ensuring seamless transportation of your goods across borders and continents. From meticulous planning to precise execution, our team of seasoned professionals is dedicated to delivering your cargo safely and on time, every time. </p>
                  <h5>NexGen Logistics: Streamlining Your Supply Chain </h5>
                  <p>Welcome to NexGen, your premier destination for global logistics solutions. At NexGen, we pride ourselves on offering a seamless supply chain experience that caters to your every need. Our robust network spans the globe, ensuring efficient transportation of goods across international borders. With <strong>cutting-edge technology</strong> and <strong>innovative strategies</strong>, we optimize every step of the logistics process, from sourcing to delivery. Trust NexGen to streamline your supply chain, enhance visibility, and <strong>maximize cost-effectiveness</strong>. Experience unmatched reliability and efficiency with NexGen - your trusted partner in global logistics. </p>

                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="page1-left-text">
                      <h6>Optimizing Supply Chains with NexGen Technology </h6>
                      <p>
                        <strong>NexGen</strong> offers comprehensive supply chain solutions for global logistics, ensuring seamless transportation of goods worldwide. Our expert team leverages cutting-edge technology and industry expertise to optimize your supply chain, maximizing efficiency and minimizing costs.</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="page1-right-pic">
                      <img src={image3} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
            <div className="service-page1-right">
              <div className="service-menu">
                <h6>Service Menu</h6>
                <ul>
                <Link to="/heavy"> <li>Heavy haul <FaAngleRight /></li></Link> 
                <Link to="/Lft">  <li>Air Freight <FaAngleRight /></li></Link> 
                <Link to="/ocean">  <li>Ocean Freight  <FaAngleRight /></li></Link> 
                <Link to="/ware">  <li>Drayage And Warehousing <FaAngleRight /></li></Link> 
                <Link to="/supply">  <li>Supply Chain <FaAngleRight /></li></Link> 
                <Link to="/packaging">   <li>Packaging <FaAngleRight /></li></Link> 
                 
                </ul>
              </div>
              <div className="service-menu">
                <h6>Fill The Form</h6>
                <div className="form-fill">  <span>
                  <FaUserAlt /></span>
                  <input type="text" placeholder="Full Name" />
                  <span><FaEnvelope /></span>
                  <input type="email" placeholder="Your Email" />
                  <span><FaPhoneAlt /></span>
                  <input type="number" placeholder="Phone Number" />
                  <span><FaPencilAlt /></span>
                  <textarea name="" id="" cols="30" rows="7"></textarea>
                  <button>Send Now</button>
                </div>
              </div>
            </div> 
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SupplyChain;


