import React, { useState, useEffect, useRef } from "react";
import slider1 from "../Assets/4.webp";
import slider2 from "../Assets/4-1.webp";
import slider3 from "../Assets/5.webp";
import slider4 from "../Assets/5-1.webp";
import slider5 from "../Assets/1.webp";
import slider6 from "../Assets/1-1.webp";
import slider7 from "../Assets/2.webp";
import slider8 from "../Assets/2-1.webp";
import slider9 from "../Assets/3.webp";
import slider10 from "../Assets/3-1.webp";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

import { FaEnvelope, FaLocationArrow, FaPhoneAlt } from "react-icons/fa";

const FormMain = () => {

  const [sliderSettings, setSliderSettings] = useState({
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  });
  const sliderRef = useRef(null);

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(0);
    }
  }, []);

  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };







  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    locationFrom: "",
    locationTo: "",
    service: "select",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    const { name, email, phone, locationFrom, locationTo, service } = formData;

    const postData = async () => {
      const url = "https://sendingmail-6znv.onrender.com/sendmail";
      const data = new URLSearchParams();
      data.append("S_name", name);
      data.append("S_email", email);
      data.append("S_phone", phone);
      data.append("Location_form", locationFrom);
      data.append("Location_to", locationTo);
      data.append("S_services", service);


      data.append("userEmailsir", "contact@nexgengloballogistics.com");

      console.log(data);

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: data.toString(),
        });

        if (response) {
          Swal.fire({
            icon: "success",
            title: "Form Submitted!",
            text: "Your information has been submitted successfully.",
          });

        } else {
          console.error("Error:", response.statusText);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong, please try again!",
          });
        }
      } catch (error) {
        console.error("Network error:", error);
        Swal.fire({
          icon: "error",
          title: "Network Error",
          text: "An error occurred. Please try again.",
        });
      }
    };

    postData();

  };


  return (
    <>
      <section className="contact-details">
        <div className="container ">
          <div className="row">
            <div className="col-xl-6 col-lg-6 ">
              <div className="sec-title">
                <span className="sub-title">Send us email</span>
                <h2>Feel free to write</h2>
              </div>

              <form id="contact_form" name="contact_form" className="" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="mb-3">
                      <input
                        type="text"
                        name="name"
                        placeholder="Your Name"
                        required
                        onChange={handleChange}
                        value={formData.name || ""}
                        className="form-control"
                      />
                    </div>
                  </div>

                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <input
                        type="tel"
                        name="phone"
                        placeholder="Phone No"
                        required
                        onChange={handleChange}
                        value={formData.phone || ""}
                        pattern="[0-9]{10,15}"
                        title="Enter a valid phone number (10-15 digits)"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb-3">

                      <div className="single-input">
                        <div className="w-full">
                          <select
                            id="selectFreight"
                            name="service"
                            className="form-control"
                            onChange={handleChange}
                            value={formData.service || ""}
                            required
                          >
                            <option value="" disabled>Select Service</option>
                            <option value="heavy-haul">Heavy Haul</option>
                            <option value="air-freight">Air Freight</option>
                            <option value="ocean-freight">Ocean Freight</option>
                            <option value="drayage-and-warehousing">Drayage And Warehousing​</option>
                            <option value="supply-chain">Supply Chain</option>
                            <option value="packaging">Packaging</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="mb-3">

                      <input
                        type="text"
                        name="locationFrom"
                        placeholder="Location From"
                        required
                        className="form-control"
                        onChange={handleChange}
                        value={formData.locationFrom || ""}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <input
                        type="text"
                        name="locationTo"
                        placeholder="Location To"
                        required
                        className="form-control"
                        onChange={handleChange}
                        value={formData.locationTo || ""}
                      />
                    </div>
                  </div>
                </div>

                <div className="disclaimer-main">

                  <p className="mb-0">
                    By providing my phone number to “NexGen Global Logistics”, I agree and acknowledge that “NexGen Global Logistics” may send
                    text messages {""}
                    {isExpanded ? (
                      <>
                        {" "}
                        to my wireless phone number for any purpose. Message and
                        data rates may apply.
                      </>
                    ) : (
                      <>
                        {" "}
                        <span onClick={handleToggle} className="more-info">
                          Read More
                        </span>
                      </>
                    )}
                  </p>
                  {isExpanded && (
                    <p className="disclaimer-additional">
                      Message frequency will vary, and you will be able to Opt-out by replying “STOP”. For more information on how your data will be handled please visit
                      {""}
                      <Link to="/privacy-policy" className="privacy-link">
                        "PRIVACY POLICY".
                      </Link>
                    </p>
                  )}
                </div>
                <div className="mb-5">
                  <input
                    name="form_botcheck"
                    className="form-control"
                    type="hidden"
                    value=""
                  />
                  <button
                    type="submit"
                    className="theme-btn btn-style-one"
                    data-loading-text="Please wait..."
                  >
                    <span className="btn-title">Send message</span>
                  </button>

                </div>
              </form>
            </div>
            <div className="col-xl-6 col-lg-6 ">
              <div className="contact-details__right">
                <div className="sec-title">
                  <span className="sub-title">Need any help?</span>
                  <h2>Get in touch with us</h2>
                  <div className="text">
                    If you have any questions, requests, or inquiries regarding
                    our logistics services, please feel free to reach out to us
                    using the contact form below.
                  </div>
                </div>
                <ul className="list-unstyled contact-details__info">
                  <li>
                    <div className="icon">
                      <span className="lnr-icon-phone-plus">
                        <FaPhoneAlt />
                      </span>
                    </div>
                    <div className="text">
                      <h6 className="mb-1">Have any question?</h6>
                      <Link to="tel: +1-877-204-0566">
                        <span>Free</span> +1-877-204-0566
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <span className="lnr-icon-envelope1">
                        <FaEnvelope />
                      </span>
                    </div>
                    <div className="text">
                      <h6 className="mb-1">Write email</h6>
                      <Link to="mailto:contact@nexgengloballogistics.com">
                        contact@nexgengloballogistics.com
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <span className="lnr-icon-location">
                        <FaLocationArrow />
                      </span>
                    </div>
                    <div className="text">
                      <h6 className="mb-1">Visit anytime</h6>
                      <span>
                        1400 Avery Ranch Blvd, Ste C200 <br />{" "}
                        Austin,Texas-78717
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="clients-section alt ">
        <div className="auto-container">
          <div className="sponsors-outer">
            <Slider ref={sliderRef} {...sliderSettings}>
              <div className="client-block">
                <Link to="#" className="image">
                  <img src={slider1} alt="" />
                  <img src={slider2} alt="" />
                </Link>
              </div>
              <div className="client-block">
                <Link to="#" className="image">
                  <img src={slider3} alt="" />
                  <img src={slider4} alt="" />
                </Link>
              </div>
              <div className="client-block">
                <Link to="#" className="image">
                  <img src={slider5} alt="" />
                  <img src={slider6} alt="" />
                </Link>
              </div>
              <div className="client-block">
                <Link to="#" className="image">
                  <img src={slider7} alt="" />
                  <img src={slider8} alt="" />
                </Link>
              </div>
              <div className="client-block">
                <Link to="#" className="image">
                  <img src={slider9} alt="" />
                  <img src={slider10} alt="" />
                </Link>
              </div>

              {/* Repeat similar structures for other clients */}
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};

export default FormMain;
